import { ButtonHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';


import styles from './Button.module.scss';
import ThemeColorPresets from '../../models/enums/theme-color-presets.enum';
import ButtonVariants from '@/components/Recommender/models/enums/button-variants.enum'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  preset?: ThemeColorPresets;
  variant?: ButtonVariants;
}

export interface IconButtonProps extends ButtonProps {
  icon?: string;
  image?: string;
  reverse?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      preset = ThemeColorPresets.PRIMARY,
      variant = ButtonVariants.CONTAINED,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        className={cn(
          styles.button,
          styles[preset],
          styles[variant],
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'CustomRegularButton';
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon,
      image = '',
      variant = ButtonVariants.OUTLINED,
      reverse,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        className={cn(
          styles.button,
          styles.icon,
          styles[variant],
          { [styles.reverse]: reverse },
          className,
        )}
        ref={ref}
        {...props}
      >
        {!image && icon ? <i className={icon} /> : null}
        {image ? <img src={image} className={styles.image} alt="img" /> : null}
        {props.children}
      </button>
    );
  },
);

IconButton.displayName = 'CustomIconButton';

export { Button, IconButton };
