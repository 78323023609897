import React from "react";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import NewsWidgetWrapper from "./components/NewsWidgetWrapper";

import App from "@/App";
import Recommender from '@/components/Recommender/Recommender'

const AppWrapper: React.FC = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value}>
            <div className="tabsWrapper">
                <div className="header">
                    <TabList onChange={handleChange}
                             textColor="primary"
                             indicatorColor="primary"
                             sx={{
                                 '.MuiTabs-indicator': {'bottom': 'none', 'top': 0, 'height': '3px'}
                             }}>
                        <Tab label="Animation Widget" value="1" />
                        <Tab label="Bet-Insights" value="2" />
                        <Tab label="News Widget" value="3" />
                        <Tab label="Recommender" value="4" />
                    </TabList>
                </div>
                <div className="body">
                    <TabPanel value="1" className="tabsItem"><App /></TabPanel>
                    <TabPanel value="2" className="tabsItem">
                        <iframe src="https://tt.bcua.io/" title="BetInside" style={{width:'100%',height:'100%'}} />
                    </TabPanel>
                    <TabPanel value="3" className="tabsItem">
                        <NewsWidgetWrapper />
                    </TabPanel>
                    <TabPanel value="4" className="tabsItem">
                        <Recommender />
                    </TabPanel>
                </div>
            </div>
        </TabContext>
    );
};

export default AppWrapper;
