enum Sports {
  FOOTBALL = 'football',
  BASKETBALL = 'basketball',
  ICE_HOCKEY = 'ice_hockey',
  E_SPORTS = 'e_sports',
  TENNIS = 'tennis',
  AMERICAN_FOOTBALL = 'american_football',
  BASEBALL = 'baseball',
  DOTA_2 = 'dota_2',
  LOL = 'league_of_legends',
  STARCRAFT = 'starcraft',
  FIFA = 'fifa',
  CS2 = 'counter-strike_2',
  VALORANT = 'valorant',
  BOXING = 'boxing',
  HANDBALL = 'handball',
  OVERWATCH2 = 'overwatch_2',
}

export default Sports;
