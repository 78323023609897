import { useEffect, useState } from 'react'

let con: WebSocket | null = null;

export const useRecommenderData = () => {
  const [connection, setConnection] = useState<WebSocket | null>(null);
  const [isConnectionSet, setIsConnectionSet] = useState(false);
  const [recommenderData, setRecommenderData] = useState<any>();

  useEffect(() => {
    if (!connection) {
      const swarmSocket = new WebSocket('wss://eu-swarm-newm.betcoswarm.com/');
      setConnection(swarmSocket);
      con = swarmSocket;
    }

    return () => {
      connection?.close();
      con?.close();
    }
  }, [])

  useEffect(() => {
    if (!connection) return

    connection.onopen = () => {
      connection.send(requestSession);
      setIsConnectionSet(true)
    }

    connection.onmessage = (data) => {
      const socketData = JSON.parse(data.data);

      if (socketData.rid === 3) {
        setRecommenderData(socketData.data);
      }
    }
  }, [connection])


  const sendRequestForRecommenderData = (sports: number[], games: number[], regions: number[], competitions: number[]) => {
    const rid = 3;

    const request = getRequest(sports, games, regions, competitions, rid);
    connection?.send(request);
  }

  return { isConnectionSet, sendRequestForRecommenderData, recommenderData }
}

const requestSession = JSON.stringify({
  command: 'request_session',
  rid: 1,
  params: { language: 'eng', site_id: 18746530 },
});

const getRequest = (sports: number[], games: number[], regions: number[], competitions: number[], rid: number) => {
  return JSON.stringify({
    'command': 'get',
    'params': {
      'source': 'betting',
      'what': {
        'sport': ['id', 'name', 'alias'],
        'competition': ['name', 'id'],
        'game': [['id', 'markets_count', 'is_blocked', 'is_stat_available', 'show_type', 'team1_name', 'team2_name', 'team1_id', 'team2_id', 'sportcast_id', 'start_ts', 'is_live', 'favorite_order', 'info', 'stats', 'region_alias']],
        'market': ['type', 'name', 'display_key', 'base', 'id', 'express_id'],
        'event': ['id', 'price', 'type_1', 'name', 'base', 'order'],
      },
      'where': {
        'sport': { 'type': { '@ne': 0 }, 'id': { '@in': sports } },
        'market': { 'display_key': 'WINNER', 'display_sub_key': 'MATCH' },
        'game': { 'id': { '@in': games } },
        'region': { 'id': { '@in': regions } },
        'competition': { 'id': { '@in': competitions } },
      },
      'subscribe': true,
    },
    'rid': rid,
  })
}
