import React, { FC } from 'react';
import cn from 'classnames';

import styles from './CommonSliderArrows.module.scss';
import ButtonVariants from '@/components/Recommender/models/enums/button-variants.enum'
import { IconButton } from '@/components/Recommender/components/Button/Button'

interface CommonSliderArrowsProps {
  onPrevious: () => void;
  onNext: () => void;
  className?: string;
}

const CommonSliderArrows: FC<CommonSliderArrowsProps> = ({
  onNext,
  onPrevious,
  className,
}) => {
  return (
    <div className={cn(styles.arrowsWrapper, className)}>
      <IconButton
        icon="ficon-left"
        className={styles.arrowButtonLeft}
        variant={ButtonVariants.OUTLINED}
        onClick={onPrevious}
      />
      <IconButton
        icon="ficon-right"
        className={styles.arrowButtonRight}
        variant={ButtonVariants.OUTLINED}
        onClick={onNext}
      />
    </div>
  );
};

export default CommonSliderArrows;
