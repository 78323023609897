import React, { memo } from 'react'
import CommonSlider from '@/components/Recommender/components/CommonSlider/CommonSlider'
import GameEventCard from '@/components/Recommender/components/GameEventCard/GameEventCard'
import { RecommenderMatchInterface } from '@/components/Recommender/models/interfaces/recommender-match.interface'
import { ISwarmMatch } from '@/components/Recommender/models/interfaces/swarm-match.interface'
import styles from './RecommenderSlider.module.scss';
import Slider from '@/components/Recommender/components/RecommenderSlider/Slider/Slider'

interface SwarmMatches {
  sport: {
    [key: number]: {
      alias: string;
      id: number;
      name: string;
      competition: {
        [key: number]: {
          id: number;
          name: string;
          game: {
            [key: number]: {
              'id': number,
              'markets_count': number,
              'is_blocked': number,
              'is_stat_available': boolean,
              'team1_name': string,
              'team2_name': string,
              'team1_id': number,
              'team2_id': number,
              'sportcast_id': number,
              'start_ts': number,
              'is_live': number,
              'favorite_order': number,
              'region_alias': string,
              'market': {
                [key: number]: {
                  'type': string,
                  'name': string,
                  'display_key': string,
                  'id': number,
                  'express_id': number,
                  'event': {
                    [key: number]: {
                      'id': number,
                      'price': number,
                      'type_1': string,
                      'name': string,
                      'order': number
                    },
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

const RecommenderSlider: React.FC<{ matches: SwarmMatches }> = memo(({ matches }) => {
  let allMatches: ISwarmMatch[] = []
  const sports = Object.values(matches.sport) || []
  const result: ISwarmMatch[][] = []

  sports.forEach(sport => {
    const sportName = sport.name
    const competitions = Object.values(sport.competition)

    competitions.forEach(competition => {
      const competitionName = competition.name
      const games = Object.values(competition.game)

      games.forEach(game => {
        const team1_name = game.team1_name
        const team2_name = game.team2_name
        const team1_id = game.team1_id
        const team2_id = game.team2_id
        const start_ts = game.start_ts

        const markets = Object.values(game.market)

        markets.forEach(market => {
          const selections = Object.values(market.event)
          const W1 = selections.find(s => s.type_1 === 'W1')
          const W2 = selections.find(s => s.type_1 === 'W2')
          const X = selections.find(s => s.type_1 === 'X')

          const home_m_name = W1?.name || '1'
          const draw_m_name = X?.name || 'X'
          const away_m_name = W2?.name || '2'
          const home_m_value = W1?.price || 0
          const draw_m_value = X?.price || 0
          const away_m_value = W2?.price || 0

          allMatches.push({
            sport_name: sportName,
            compName: competitionName,
            team1_name,
            team2_name,
            team1_id,
            team2_id,
            home_m_name,
            draw_m_name,
            away_m_name,
            home_m_value,
            draw_m_value,
            away_m_value,
            start_ts,
          })
        })
      })
    })

    result.push(allMatches)
    allMatches = []
  })

  return (
    <>
      {
        result.map((sport, index) => (
          <div key={index} className={styles.sliderWrapper} style={{width: `${300*sport.length}px`}}>
            <Slider matches={sport} />
          </div>
        ))
      }
    </>

  )
})

export default RecommenderSlider
