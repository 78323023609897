import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import {Provider} from "react-redux";

import store from "./store/store";
import reportWebVitals from "./reportWebVitals";

import "font-awesome/css/font-awesome.min.css";
import AppWrapper from "@/AppWrapper";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <AppWrapper/>
    </Provider>
);

reportWebVitals();
