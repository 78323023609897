import { forwardRef, memo, ReactNode, Fragment } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import styles from './CommonSlider.module.scss';

const RESPONSIVE_CONFIG = {
  desktop: {
    breakpoint: { max: 3000, min: 1560 },
    items: 3.4,
  },
  laptop: {
    breakpoint: { max: 1560, min: 1240 },
    items: 2.4,
  },
  tablet: {
    breakpoint: { max: 1240, min: 1065 },
    items: 1.8,
  },
  mobileL: {
    breakpoint: { max: 960, min: 675 },
    items: 1.9,
  },
  mobileM: {
    breakpoint: { max: 675, min: 0 },
    items: 1,
  },
};

interface SliderProps {
  items: {
    category: string | undefined;
    component: ReactNode;
  }[];
  arrows?: boolean;
  renderButtonGroupOutside?: boolean;
  customRightArrow?: React.ReactElement | null;
  customLeftArrow?: React.ReactElement | null;
  customButtonGroup?: React.ReactElement | null;
  responsive?: ResponsiveType;
}

const CommonSlider = memo(
  forwardRef<Carousel, SliderProps>(
    (
      {
        items,
        arrows = false,
        renderButtonGroupOutside = true,
        customRightArrow = null,
        customLeftArrow = null,
        customButtonGroup = null,
        responsive = RESPONSIVE_CONFIG,
        ...rest
      },
      ref,
    ) => {
      return (
        <div className={styles.sliderWrapper} data-qa="sliderWrapper">
          <Carousel
            ref={ref}
            partialVisible
            containerClass={styles.sliderContainer}
            sliderClass={styles.sliderList}
            itemClass={styles.sliderItem}
            arrows={arrows}
            responsive={responsive}
            customRightArrow={customRightArrow}
            customLeftArrow={customLeftArrow}
            renderButtonGroupOutside={renderButtonGroupOutside}
            customButtonGroup={customButtonGroup}
            {...rest}
          >
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>{item.component}</Fragment>
            ))}
          </Carousel>
        </div>
      );
    },
  ),
);

export default CommonSlider;
