import football from './sport/football.svg';
import basketball from './sport/basketball.svg';
import handball from './sport/handball.svg';

type SportInfo = {
  color: string;
  img: string;
  position: 'horizontal' | 'vertical';
};

function checkSport(sportName: string | undefined): SportInfo {
  let sportInfo: SportInfo;
  switch (sportName?.toLowerCase()) {
    case 'football':
      sportInfo = {
        color: '#3c953f',
        img: football,
        position: 'horizontal',
      };
      break;
    case 'basketball':
      sportInfo = {
        color: '#fb922d',
        img: basketball,
        position: 'vertical',
      };
      break;
    case 'tennis':
      sportInfo = {
        color: '#d94a38',
        img: handball,
        position: 'horizontal',
      };
      break;
    case 'american football':
      sportInfo = {
        color: '#8b4513',
        img: handball,
        position: 'horizontal',
      };
      break;
    case 'handball':
      sportInfo = {
        color: '#303e9f',
        img: handball,
        position: 'horizontal',
      };
      break;
    case 'counter-strike 2':
      sportInfo = {
        color: '#1b2838',
        img: 'none',
        position: 'horizontal',
      };
      break;
    case 'dota 2':
      sportInfo = {
        color: '#aa0000',
        img: 'none',
        position: 'horizontal',
      };
      break;
    case 'league of legends':
      sportInfo = {
        color: '#1c3a57',
        img: 'none',
        position: 'horizontal',
      };
      break;
    case 'overwatch 2':
      sportInfo = {
        color: '#faa61a',
        img: 'none',
        position: 'horizontal',
      };
      break;
    default:
      sportInfo = {
        color: 'transparent',
        img: 'none',
        position: 'horizontal',
      };
      break;
  }
  return sportInfo;
}

export default checkSport;
