const PremierLeagueTeams2024: [number, string][] = [
  [403, 'Tottenham Hotspur'],
  [946, 'West Ham United'],
  [944, 'Southampton'],
  [937, 'Leicester City'],
  [397, 'Newcastle United'],
  [926, 'Brighton Hove Albion'],
  [396, 'Manchester United'],
  [882, 'Brentford'],
  [935, 'Ipswich Town'],
  [392, 'Everton'],
  [393, 'Fulham'],
  [388, 'Aston Villa'],
  [880, 'Bournemouth'],
  [387, 'Arsenal'],
  [394, 'Liverpool'],
  [391, 'Chelsea'],
  [406, 'Wolverhampton Wanderers'],
  [395, 'Manchester City'],
  [940, 'Nottingham Forest'],
  [931, 'Crystal Palace'],
];

export default PremierLeagueTeams2024;
