import React, { memo, useCallback, useRef } from 'react'
import GameEventCard from '@/components/Recommender/components/GameEventCard/GameEventCard'
import CommonSlider from '@/components/Recommender/components/CommonSlider/CommonSlider'
import { ISwarmMatch } from '@/components/Recommender/models/interfaces/swarm-match.interface'
import Carousel from 'react-multi-carousel'
import CommonSliderArrows
  from '@/components/Recommender/components/RecommenderSlider/Slider/CommonSliderArrows/CommonSliderArrows'
import styles from './Slider.module.scss';


const Slider: React.FC<{matches: ISwarmMatch[]}> = memo(({matches}) => {
  const refSlider = useRef<Carousel | null>(null);

  const handleNext = useCallback(
    () => refSlider?.current && refSlider.current.next(1),
    [refSlider],
  );
  const handlePrevious = useCallback(
    () => refSlider?.current && refSlider.current.previous(1),
    [refSlider],
  );

  return (
    <>
      <CommonSliderArrows
        className={styles.sliderArrows}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      <CommonSlider
        ref={refSlider}
        items={matches.map((match) => ({
          category: match.sport_name,
          component: <GameEventCard match={match} />,
        }))}
      />
    </>
  )
})

export default Slider;
