import Sports from "../models/enums/sports.enum";


const SPORT_ICON_MAP = new Map<Sports, string>();

SPORT_ICON_MAP.set(Sports.FOOTBALL, 'ficon-football');
SPORT_ICON_MAP.set(Sports.BASEBALL, 'ficon-baseball');
SPORT_ICON_MAP.set(Sports.BASKETBALL, 'ficon-basketball');
SPORT_ICON_MAP.set(Sports.AMERICAN_FOOTBALL, 'ficon-american-football');
SPORT_ICON_MAP.set(Sports.TENNIS, 'ficon-tennis');
SPORT_ICON_MAP.set(Sports.ICE_HOCKEY, 'ficon-ice-hockey');
SPORT_ICON_MAP.set(Sports.HANDBALL, 'ficon-handball');
SPORT_ICON_MAP.set(Sports.DOTA_2, 'ficon-dota2');
SPORT_ICON_MAP.set(Sports.LOL, 'ficon-league-of-legends-wild-rift');
SPORT_ICON_MAP.set(Sports.FIFA, 'ficon-fifa');
SPORT_ICON_MAP.set(Sports.CS2, 'ficon-counter-strike');
SPORT_ICON_MAP.set(Sports.VALORANT, 'ficon-valorant');
SPORT_ICON_MAP.set(Sports.STARCRAFT, 'ficon-starcraft');
SPORT_ICON_MAP.set(Sports.OVERWATCH2, 'ficon-overwatch');

export default SPORT_ICON_MAP;
