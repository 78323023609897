import React, { memo, useEffect, useState } from 'react'
import { useRecommenderData } from '@/components/Recommender/hooks/useRecommenderData'
import { RecommenderResponseInterface } from '@/components/Recommender/models/interfaces/recommender-response.interface'
import RecommenderSlider from '@/components/Recommender/components/RecommenderSlider/RecommenderSlider'
import styles from './Recommender.module.scss';

const Recommender: React.FC = memo(() => {
  const [recommendedMatches, setRecommendedMatches] = useState<RecommenderResponseInterface | null>(null)
  const { isConnectionSet, sendRequestForRecommenderData, recommenderData } = useRecommenderData()

  useEffect(() => {
    fetch('https://recommender.p13r.bcua.io/api/v3/recommendations/partners/18746530?ab_tests=0')
      .then(res => res.json())
      .then(data => setRecommendedMatches(data))
  }, [])

  useEffect(() => {
    if (isConnectionSet && recommendedMatches) {
      const sports = new Set<number>()
      const games: number[] = []
      const regions = new Set<number>()
      const competitions = new Set<number>()

      recommendedMatches.data.forEach(m => {
        sports.add(m.sport)
        games.push(m.game)
        regions.add(m.region)
        competitions.add(m.competition)
      })

      sendRequestForRecommenderData(Array.from(sports), games, Array.from(regions), Array.from(competitions))
    }
  }, [isConnectionSet, recommendedMatches])

  useEffect(() => {
    console.log(recommenderData)
  }, [recommenderData])

  return (
    <>
      <h2 className={styles.title}>Recommended matches</h2>
      <div className={styles.sliderWrapper}>
        <div className={styles.slider}>
          {recommenderData?.data && <RecommenderSlider matches={recommenderData.data}/>}
        </div>
      </div>
    </>
  )
})

export default Recommender
