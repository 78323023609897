import {FC, memo, useEffect, useRef, useState} from "react";

import styles from "./styles/NewsWidgetWrapper.module.scss";

import PremierLeagueTeams2024 from "@/components/NewsWidgetWrapper/fake-data/premier-league-teams-2024";
import {
    PostMessageFormClientInterface
} from "@/components/NewsWidgetWrapper/models/interfaces/PostMessageFormClient.interface";

const MATCHES_ARRAY: [number, number][] = [[0, 12], [1, 13], [2, 14], [3, 15], [4, 16], [5, 15], [6, 16], [7, 17], [8, 18], [9, 19]];

const NewsWidgetWrapper: FC = memo(() => {
    const [widgetWidth, setWidgetWidth] = useState(450);
    const [activeMatch, setActiveMatch] = useState<[number, number] | null>(null);
    const frameRef = useRef<HTMLIFrameElement>(null);

    function generatePostMessage(teams: [number, number]): PostMessageFormClientInterface {
        return {
            skinId: 1019,
            swarmUrl: '',
            gameId: Date.now(),
            lang: 'en',
            sport: 'football',
            team1Name: PremierLeagueTeams2024[teams[0]][1],
            team2Name: PremierLeagueTeams2024[teams[1]][1],
            team1Id: PremierLeagueTeams2024[teams[0]][0],
            team2Id: PremierLeagueTeams2024[teams[1]][0],
            marketId: 0,
            eventIds: [
                { id: 0, name: 'W1', price: 1.19, base: '' },
                { id: 0, name: 'Draw', price: 3.12, base: '' },
                { id: 0, name: 'W2', price: 2.26, base: '' },
            ]
        };
    }

    useEffect(() => {
        function postMessageHandler(message: any): void {
            if (message?.data?.action === 'ready') {
                setActiveMatch(MATCHES_ARRAY[0]);
                window.removeEventListener('message', postMessageHandler);
            }
        }

        window.addEventListener('message', postMessageHandler);
    }, []);

    useEffect(() => {
        !!activeMatch && frameRef.current!.contentWindow!.postMessage({
            action: 'open_news',
            data: generatePostMessage(activeMatch)
        }, '*',);
    }, [activeMatch]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.rangeWrapper}>
                    <div className={styles.point}>300px</div>
                    <input type="range"
                           min={300}
                           max={600}
                           step={1}
                           value={widgetWidth}
                           onChange={(value) => setWidgetWidth(+value.target.value)}/>
                    <div className={styles.point}>600px</div>
                    <div className={styles.rangeValue}>{widgetWidth}px</div>
                </div>
            </div>
            <div className={styles.body}>
                <iframe ref={frameRef} src="https://news-widget.dev.pr13.bcua.io/" title="NewsWidget"
                        style={{width: `${widgetWidth}px`, height: '100%'}}/>
                <div className={styles.matchSelectorWrapper}>
                    <div className={styles.matchSelectorTitle}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="SectionCaption_icon__SNlHf SectionCaption_active__qDeGz">
                            <path fill="#7166F9"
                                  d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM20.79 11.75L18.72 10.28L19.72 7.72C20.3966 8.95734 20.7637 10.34 20.79 11.75ZM18.86 6.5L17.54 10L15.13 10.81L12.6 8.72V6.31L15.76 4.05C16.9621 4.62695 18.0209 5.46378 18.86 6.5ZM10.45 14.4L9.68 11.71L12 9.78L14.32 11.71L13.55 14.4H10.45ZM14.45 3.54L12 5.26L9.59 3.54C11.1647 3.08659 12.8353 3.08659 14.41 3.54H14.45ZM8.28 4.05L11.4 6.31V8.72L8.86 10.84L6 10L5.12 6.5C5.96484 5.46144 7.03073 4.62445 8.24 4.05H8.28ZM4.83 10.28L3.22 11.62C3.27125 10.3145 3.61287 9.03686 4.22 7.88L4.83 10.28ZM3.28 13.13L5.64 11.13L8.51 12L9.35 14.91L7.68 17.4H5.07C4.0999 16.1637 3.48148 14.6885 3.28 13.13ZM7.57 18.6L8 19.82C7.35501 19.484 6.75101 19.0746 6.2 18.6H7.57ZM9.44 20.42L8.66 18.09L10.32 15.6H13.68L15.34 18.09L14.56 20.42C12.891 20.9269 11.109 20.9269 9.44 20.42ZM17.8 18.6C17.249 19.0746 16.645 19.484 16 19.82L16.4 18.6H17.8ZM18.93 17.4H16.32L14.65 14.9L15.49 12L17.9 11.2L20.71 13.2C20.4999 14.7331 19.8855 16.1827 18.93 17.4Z"/>
                        </svg>
                        <span>Match selector</span>
                    </div>
                    <div className={styles.matchWrapper}>
                        {
                            MATCHES_ARRAY.map((match, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <button key={index}
                                        type="button"
                                        onClick={() => setActiveMatch(match)}
                                        className={`${styles.match} ${activeMatch?.join() === match.join() ? styles.active : ''}`}>
                                    {PremierLeagueTeams2024[MATCHES_ARRAY[index][0]][1]} - {PremierLeagueTeams2024[MATCHES_ARRAY[index][1]][1]}
                                </button>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default NewsWidgetWrapper;
