import React from 'react'

const SaveIcon: React.FC = () => (
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.30616 10.3866C6.19533 10.3866 6.08449 10.3457 5.99699 10.2582L5.12199 9.38323C4.95283 9.21406 4.95283 8.93406 5.12199 8.7649C5.29116 8.59573 5.57116 8.59573 5.74033 8.7649L6.30616 9.33073L8.33033 7.30656C8.49949 7.1374 8.77949 7.1374 8.94866 7.30656C9.11783 7.47573 9.11783 7.75573 8.94866 7.9249L6.61533 10.2582C6.53366 10.3457 6.41699 10.3866 6.30616 10.3866Z'
      fill='white'
    />
    <path
      d='M8.16699 4.43685H5.83366C5.27366 4.43685 4.22949 4.43685 4.22949 2.83268C4.22949 1.22852 5.27366 1.22852 5.83366 1.22852H8.16699C8.72699 1.22852 9.77116 1.22852 9.77116 2.83268C9.77116 3.39268 9.77116 4.43685 8.16699 4.43685ZM5.83366 2.10352C5.25616 2.10352 5.10449 2.10352 5.10449 2.83268C5.10449 3.56185 5.25616 3.56185 5.83366 3.56185H8.16699C8.89616 3.56185 8.89616 3.41018 8.89616 2.83268C8.89616 2.10352 8.74449 2.10352 8.16699 2.10352H5.83366Z'
      fill='white'
    />
    <path
      d='M8.75 13.7701H5.25C1.97167 13.7701 1.3125 12.2651 1.3125 9.8326V6.3326C1.3125 3.6726 2.275 2.5351 4.64333 2.4126C4.8825 2.40094 5.0925 2.58177 5.10417 2.82677C5.11583 3.07177 4.92917 3.2701 4.69 3.28177C3.03333 3.3751 2.1875 3.87094 2.1875 6.3326V9.8326C2.1875 11.9909 2.61333 12.8951 5.25 12.8951H8.75C11.3867 12.8951 11.8125 11.9909 11.8125 9.8326V6.3326C11.8125 3.87094 10.9667 3.3751 9.31 3.28177C9.07083 3.2701 8.88417 3.0601 8.89583 2.82094C8.9075 2.58177 9.11167 2.3951 9.35667 2.40677C11.725 2.5351 12.6875 3.6726 12.6875 6.32677V9.82677C12.6875 12.2651 12.0283 13.7701 8.75 13.7701Z'
      fill='white'
    />
  </svg>
)

export default SaveIcon
