import { FC, memo } from 'react';
import classNames from 'classnames';

import { IconButton } from './../Button/Button';

import style from './GameEventCard.module.scss';
import { ISwarmMatch } from '@/components/Recommender/models/interfaces/swarm-match.interface'
import SPORT_ICON_MAP from '@/components/Recommender/helpers/SportIconMap'
import Sports from '@/components/Recommender/models/enums/sports.enum'
import checkSport from '@/components/Recommender/helpers/checkSport'

const GameEventCard: FC<{ match: ISwarmMatch }> = memo(({ match }) => {
  const {
    team1_name,
    team1_id,
    team2_name,
    team2_id,
    sport_name,
    compName,
    home_m_name,
    draw_m_name,
    away_m_name,
    home_m_value,
    draw_m_value,
    away_m_value,
    start_ts
  } = match;
  const { color, img, position } = checkSport(sport_name);

  const options: any = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  const today  = new Date((1000 * start_ts!));
  const matchDateTime = today.toLocaleDateString("en-UK", options);

  return (
    <div
      className={style.cardWrapper}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className={style.cardHeader} style={{ backgroundColor: color }}>
        <span className={style.compName}>
          <i
            className={SPORT_ICON_MAP.get(
              sport_name?.toLowerCase().replace(/ /g, '_') as Sports,
            )}
          />
          <span title={compName}>{compName}</span>
        </span>
        <i className="ficon-favorite" />
      </div>
      <div className={classNames(style.cardData, style[position])}>
        <div className={classNames(style.gameData, style[position])}>
          <div className={classNames(style.team, style[position])}>
            <img
              src={`https://statistics.bcapps.site/images/e/b/${Math.floor(Number(team1_id) / 2000)}/${team1_id}.png`}
              alt="teamOne"
              className={classNames(style.icon, style[position])}
            />
            <span className={style.teamName} title={team1_name}>
              {team1_name}
            </span>
          </div>
          {position === 'horizontal' ? (
            <div className={style.gameDate}>{matchDateTime}</div>
          ) : null}
          <div className={classNames(style.team, style[position])}>
            <img
              src={`https://statistics.bcapps.site/images/e/b/${Math.floor(Number(team2_id) / 2000)}/${team2_id}.png`}
              alt="teamTwo"
              className={classNames(style.icon, style[position])}
            />
            <span className={style.teamName} title={team2_name}>
              {team2_name}
            </span>
          </div>
        </div>
      </div>
      <div className={style.btnGroup}>
        <IconButton className={style.btn}>
          <span className={classNames(style.label, style.oddsName)}>{home_m_name}</span>
          <span className={style.label}>{home_m_value}</span>
        </IconButton>
        {position === 'horizontal' ? (
          <IconButton className={style.btn}>
            <span className={classNames(style.label, style.oddsName)}>{draw_m_name}</span>
            <span className={style.label}>{draw_m_value}</span>
          </IconButton>
        ) : null}
        <IconButton className={style.btn}>
          <span className={classNames(style.label, style.oddsName)}>{away_m_name}</span>
          <span className={style.label}>{away_m_value}</span>
        </IconButton>
      </div>
    </div>
  );
});

export default GameEventCard;
